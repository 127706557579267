import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

function HomePageTemplate({ title, content, contentComponent, data }) {
  const PageContent = contentComponent || Content
  return (
    <Layout>
      <section
        style={{ minHeight: '85vh' }}
        className="px-16 py-4 bg-light-grey"
      >
        <div className="flex justify-center">
          <div>
            <h2 className="mb-8 font-bold text-center">{title}</h2>
            <PageContent
              className="max-w-6xl text-justify leading-8"
              content={content}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

HomePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const HomePage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <HomePageTemplate
      contentComponent={HTMLContent}
      title={post.frontmatter.title}
      content={post.html}
      data={data}
    />
  )
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HomePage

export const homeQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    file(relativePath: { eq: "organ.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 15) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
